.drawer {
  position: fixed;
  right: 0;
  top: 0;
  width: 200px;
  height: 100vh;
  background-color: #eee;
  padding: 1rem;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.close-button {
  position: absolute;
  right: 10px;
  top: 10px;
}
