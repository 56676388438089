.context-menu {
  position: fixed;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth */
}

.context-menu-item {
  padding: 8px 16px;
  cursor: pointer;
  list-style-type: none;
  transition:
    background-color 0.2s,
    color 0.2s; /* Smooth transition for hover effect */
}

.context-menu-item:hover {
  background-color: #f5f5f5; /* Light grey background on hover */
  color: #333; /* Optional: Change text color on hover */
}
