.notification-modal {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 300px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
  white-space: pre-line;
  z-index: 100;
}

.notification-modal.error {
  background-color: #f8d7da;
  color: #721c24;
}

.notification-modal.success {
  background-color: #d4edda;
  color: #155724;
}
