.loading-editor .ace_content {
  color: grey;
}

.ellipsis {
  display: inline-block;
  margin-left: 10px;
}

.ellipsis::after {
  content: '...';
  animation: ellipsis 1s infinite;
}

@keyframes ellipsis {
  0% {
    content: '.';
  }
  33% {
    content: '..';
  }
  66% {
    content: '...';
  }
  100% {
    content: '....';
  }
}

.editor-container {
  position: relative;
}

.editor-content {
  position: relative;
  height: calc(100% - 30px); /* Adjust as needed */
}

.editor-status-bar {
  position: relative;
  height: 40px; /* Adjust as needed */
}

.editor-content {
  position: relative;
}

.editor-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent black */
  z-index: 10; /* Adjust as needed to ensure it covers the editor */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  pointer-events: all; /* This ensures the overlay can be "clicked" */
}
