@media (max-width: 800px) {
  span.button-text {
    display: none;
  }

  div.markdown-toolbar {
    display: none;
  }

  div.actions {
    display: none;
  }

  .tabs .button-text {
    display: none;
  }

  .editor-top-ribbon .title-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
}

.editor-top-ribbon {
  align-items: center;
  background: #f6f8fa;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  min-height: 45px;
}

.editor-top-ribbon .file-title {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.editor-top-ribbon .title-text {
  font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas,
    'Liberation Mono', monospace;
  font-size: 0.8rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  white-space: nowrap;
}

.tabs {
  display: flex;
  margin-left: 16px;
  margin-top: 5px;
}

.tabs button {
  background: none;
  border: none;
  margin-right: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
  color: #6ea1db;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
}

.tabs button.active {
  background: #ffffff;
}

.actions {
  margin-left: auto;
  margin-right: 10px;
  display: flex;
}

.action-icon {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.action-icon:hover {
  color: #6ea1db;
}

.markdown-toolbar {
  margin-left: 10px;
  white-space: nowrap;
}

.close-icon {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.6;
}

.close-icon:hover {
  opacity: 1;
}

.editor-top-ribbon .permissions-title-text {
  font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas,
    'Liberation Mono', monospace;
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
}

.permissions-actions {
  display: flex;
  align-items: center;
  /* additional styling as needed */
}
