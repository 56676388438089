/* Toolbar.css */

.toolbar {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: #eee0e0;
  color: #5b6671;
}

.avatar-icon {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  border-radius: 50%; /* Makes the image round */
  object-fit: cover; /* Ensures the image covers the area without stretching */
}

.toolbar-text {
  font-family: 'Bungee', cursive;
  font-size: 16px;
  margin-left: 10px; /* Adjust as needed */
}
