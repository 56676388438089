.search-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.selected {
  background-color: #f0f0f0; /* Change this to your preferred color */
}

.search-modal ul {
  list-style-type: none; /* Remove bullets */
}

.search-modal ul li:first-child {
  margin-top: 10px;
}

.search-modal li {
  padding: 3px; /* Add padding to list items */
}
