/* src/components/MarkdownToolbar.css */
.markdown-toolbar {
  display: flex;
  gap: 10px; /* Spacing between buttons */
}

.toolbar-button {
  background: none; /* Remove default button background */
  border: none; /* Remove default button border */
  cursor: pointer;
  padding: 5px;
}

.toolbar-button:hover {
  background-color: #f6f8fa; /* Light gray background when hovered */
}

.toolbar-button.disabled {
  color: #ccc; /* Gray color */
  cursor: not-allowed;
}
