.active-file-highlight {
  background-color: #e8f4fc;
}

.add-file-button {
  background: none;
  border: none;
  bottom: 2px;
  cursor: pointer;
  opacity: 0.2;
  position: relative;
  right: 0;
  transition: opacity 0.3s ease;
}

.add-file-button:hover {
  opacity: 1;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.collapse-all-button,
.new-folder-button {
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.3s ease;
}

.collapse-all-button:hover,
.new-folder-button:hover {
  opacity: 1;
}

.context-menu-active {
  background-color: #e1e4e8;
}

.document-list {
  margin-left: 10px;
}

.document-name {
  cursor: pointer;
  padding: 2px 0; /* Adjust padding as needed */
  margin-bottom: 3px; /* Add margin for spacing between items */
  font-size: 13px; /* Ensure consistent font size */
  display: flex; /* Flex layout for alignment */
  align-items: center; /* Vertically center content */
}

.folder-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 3px 0;
  margin: 3px 0;
  border-bottom: 1px solid #e1e4e8;
  cursor: pointer;
}

.document-name span,
.folder-name span {
  flex-grow: 1;
  padding: 2px 0;
}

.document-name:not(.editing):hover,
.folder-name:not(.editing):hover {
  background-color: #e1e4e8;
}

.document-name.editing .explorer-file-icon,
.folder-name.editing .folder-blue {
  opacity: 0.5;
}

.edit-input {
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 3px;
  padding: 2px 5px;
  width: calc(100% - 10px);
}

.explorer-file-icon {
  opacity: 0.3;
}

.file-explorer {
  background-color: #f6f8fa;
  color: #5b6671;
  font-size: 13px;
  height: 100%;
  min-width: 200px;
  overflow-y: auto;
  padding: 10px;
  width: 240px;
}

.folder-blue {
  color: rgb(196, 179, 166);
}

hr {
  background: #e1e4e8;
  border: 0;
  height: 1px;
  margin-bottom: 5px;
  margin-top: 5px;
}
