.base-document-view {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  background-color: #f6f8fa;
  color: #24292e;
  height: 100vh;
}

.document-body {
  white-space: pre-wrap; /* Preserves spacing and line breaks */
  word-wrap: break-word; /* Ensures long lines wrap */
}

.content-header {
  padding-bottom: 8px; /* Space below the header */
  margin-bottom: 16px; /* Space below the header, above the content */
  border-bottom: 1px solid #e1e4e8; /* Light grey line under the header */
  font-size: 16px; /* Size of the header font */
  font-weight: 600; /* Make the header font a bit bolder */
}

.public-document-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.public-file-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  padding: 16px;
  display: inline-block;
  margin-bottom: -1px; /* Align the border with the bottom line */
  /* No border-bottom here since we removed the underline from the header */
}

.public-document-content {
  border: 1px solid #e1e4e8;
  border-top: none; /* Removes the top border */
  border-bottom-left-radius: 6px; /* Rounds the bottom left corner */
  border-bottom-right-radius: 6px; /* Rounds the bottom right corner */
  margin-top: 0;
  width: 100%;
  max-width: 1024px;
  background-color: #fff;
  box-sizing: border-box;
  /* ... other styles ... */
}

.content-body {
  padding: 16px;
}

.public-document-header {
  background-color: #f6f8fa;
  border: 1px solid #e1e4e8;
  border-bottom: none; /* Removes the bottom border */
  display: flex;
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  border-top-left-radius: 6px; /* Rounds the top left corner */
  border-top-right-radius: 6px; /* Rounds the top right corner */
  padding: 8px 16px; /* Padding around the content */
}

.user-avatar {
  width: 30px; /* Size of the avatar */
  height: 30px; /* Size of the avatar */
  border-radius: 50%; /* Makes the avatar circular */
  object-fit: cover; /* Ensures the image covers the area well */
  margin-right: 8px; /* Space between the avatar and the username */
}

.user-info {
  font-weight: bold; /* Bold text for the username and file information */
  display: flex; /* Use flex layout */
  align-items: center; /* Align items vertically */
  white-space: nowrap; /* Prevents wrapping to a new line */
}
