.EditorShell {
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
  background-color: #f6f8fa;
  color: #24292e;
  height: 100vh;
}

.main-container {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.editor-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}

.file-explorer {
  flex-grow: 1;
  flex: 0 1 250px;
  overflow-y: auto;
}

.markdown-preview {
  border: 1px solid #e1e4e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin: 16px;
  overflow: hidden;
}

.markdown-preview iframe {
  width: 100%;
  border: none;
}
